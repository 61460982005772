<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      배송지 등록/수정<router-link to="" @click.native="goBack"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <ValidationObserver ref="obServer">
      <form @submit.prevent="regist">
        <div class="rn_my_box_18">
          <div class="info_input">
            <div class="box">
              <h3>배송지</h3>
              <ValidationProvider
                :rules="{
                  required: true,
                }"
                v-slot="{ errors }"
                name="배송지"
              >
                <div class="input">
                  <input
                    type="text"
                    v-model="addressDesc"
                    maxlength="20"
                    placeholder="예: 집"
                  />
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3>수령인</h3>
              <ValidationProvider
                ref="nickname"
                :rules="{
                  required: true,
                  min_length: 3,
                }"
                v-slot="{ errors }"
                name="수령인"
              >
                <div class="input">
                  <input type="text" maxlength="20" v-model="userName" />
                  <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="box">
              <h3>주소</h3>
              <div class="input type_address">
                <input
                  type="text"
                  name=""
                  id="zipNo"
                  readonly
                  value=""
                /><router-link to="" @click.native="goPopup"
                  ><span>주소검색</span></router-link
                >
              </div>
              <div class="input">
                <input type="text" name="" id="roadAddr" value="" readonly />
              </div>
              <div class="input">
                <input type="text" name="" value="" readonly id="addrDetail" />
              </div>
            </div>
            <p v-if="checkJuso" class="error-txt">
              주소검색을 통해 주소를 입력해주세요.
            </p>
            <div class="box">
              <h3>휴대폰번호</h3>
              <div class="input type_phone">
                <input
                  type="text"
                  name=""
                  v-model="cellphone[0]"
                  maxlength="4"
                  @keyup="checkPhoneValue"
                />
                <span>-</span
                ><input
                  type="text"
                  name=""
                  v-model="cellphone[1]"
                  maxlength="4"
                  @keyup="checkPhoneValue"
                /><span>-</span
                ><input
                  type="text"
                  name=""
                  v-model="cellphone[2]"
                  maxlength="4"
                  @keyup="checkPhoneValue"
                />
              </div>
            </div>
            <p v-if="checkPhone" class="error-txt">
              휴대폰 번호는 '-' 없는 휴대폰 번호 형식으로 입력해주세요.
            </p>
            <div class="box">
              <h3>일반번호</h3>
              <div class="input type_phone">
                <select name="" v-model="phone[0]">
                  <option value="">선택</option>
                  <option value="02">02</option>
                  <option value="031">031</option>
                  <option value="032">032</option>
                  <option value="033">033</option>
                  <option value="041">041</option>
                  <option value="042">042</option>
                  <option value="043">043</option>
                  <option value="051">051</option>
                  <option value="052">052</option>
                  <option value="053">053</option>
                  <option value="054">054</option>
                  <option value="055">055</option>
                  <option value="061">061</option>
                  <option value="062">062</option>
                  <option value="063">063</option>
                  <option value="064">064</option>
                  <option value="070">070</option></select
                ><span>-</span
                ><input
                  type="text"
                  name=""
                  v-model="phone[1]"
                  maxlength="4"
                /><span>-</span
                ><input type="text" name="" v-model="phone[2]" maxlength="4" />
              </div>
            </div>
          </div>
          <div class="sect_check">
            <label
              ><input type="checkbox" name="" v-model="defaultCheck" />기본
              배송지로 설정</label
            >
          </div>
        </div>
      </form>
    </ValidationObserver>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_btn yellow">
      <div>
        <router-link to="" @click.native="regist" v-if="!this.addressSeq"
          >배송지 등록</router-link
        >
        <router-link to="" @click.native="regist" v-else
          >배송지 수정</router-link
        >
      </div>
    </div>
    <!---->
  </div>
</template>

<script>
export default {
  data() {
    return {
      addressDesc: null,
      userName: null,
      zipNo: null,
      roadAddr: null,
      addrDetail: null,
      defaultCheck: false,
      mode: "insert",
      cellphone: ["010", null, null],
      phone: ["", null, null],
      checkJuso: false,
      checkPhone: false,
      addressSeq: this.$route.params.seq,
      reviewSeq: this.$route.query.review_seq,
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    if (this.$store.state.mypage.shippingAddress > 10) {
      this.$toast.default("배송지는 최대 10개까지 등록 가능합니다.");
      this.$router.push("/mypage/shipping_address_list");
    }
    if (this.addressSeq) {
      this.getShippingAddress();
    }
  },
  methods: {
    goBack() {
      const url = this.$store.state.common.referer;
      this.$store.dispatch("common/setReferer", null);
      if (url) {
        this.$store.dispatch("common/setReferer", {
          path: this.$route.path,
        });
        console.log(url.path);
        if (this.reviewSeq) {
          url.path += `?review_seq=${this.reviewSeq}`;
        }
        this.$router.push(url.path);
        return false;
      }
      this.$router.go(-1);
    },
    goPopup() {
      window.open(
        "/ramm_api/jusopopup",
        "pop",
        "scrollbars=yes, resizable=yes"
      );
    },
    async regist() {
      const validate = await this.$refs.obServer.validate();
      const url = this.$store.state.common.referer;
      this.zipNo = document.querySelector("#zipNo").value;
      this.roadAddr = document.querySelector("#roadAddr").value;
      this.addrDetail = document.querySelector("#addrDetail").value;
      if (validate) {
        if (!this.checkPhoneValue()) {
          return false;
        }

        this.zipNo = document.querySelector("#zipNo").value;
        this.roadAddr = document.querySelector("#roadAddr").value;
        this.addrDetail = document.querySelector("#addrDetail").value;

        if (!this.zipNo || !this.roadAddr || !this.addrDetail) {
          // this.checkJuso = true;
          this.$toast.default("주소검색을 통해 주소를 입력해주세요.");
          return false;
        }

        let formData = new FormData();
        formData.append("address_seq", this.addressSeq);
        formData.append("address_description", this.addressDesc.trim());
        formData.append("recipient_user_name", this.userName.trim());
        formData.append("nation_select", "KOREA");
        formData.append("recipient_new_zipcode", this.zipNo);
        formData.append("recipient_address_street", this.roadAddr);
        formData.append("recipient_address", this.roadAddr);
        formData.append("recipient_address_detail", this.addrDetail);

        if (
          this.phone[0] !== "" &&
          this.phone[1] !== "" &&
          this.phone[2] !== ""
        ) {
          formData.append("recipient_phone[]", this.phone[0]);
          formData.append("recipient_phone[]", this.phone[1]);
          formData.append("recipient_phone[]", this.phone[2]);
        } else {
          formData.append("recipient_phone[]", "");
          formData.append("recipient_phone[]", "");
          formData.append("recipient_phone[]", "");
        }

        formData.append("recipient_cellphone[]", this.cellphone[0]);
        formData.append("recipient_cellphone[]", this.cellphone[1]);
        formData.append("recipient_cellphone[]", this.cellphone[2]);
        formData.append("select_address_group", "기본그룹");
        if (this.defaultCheck) {
          formData.append("save_delivery_address", "1");
        }
        formData.append("insert_mode", this.mode);
        formData.append("international", "domestic");
        formData.append("recipient_address_type", "street");

        if (this.mode === "update") {
          this.$confirm("배송지를 수정하시겠습니까?")
            .then(async () => {
              await this.$store.dispatch("mypage/deliveryAddress", formData);
              this.$toast.default(this.$store.state.mypage.msg);
            })
            .catch(() => {
              return false;
            });
        } else {
          await this.$store.dispatch("mypage/deliveryAddress", formData);
          this.$toast.default(this.$store.state.mypage.msg);
          if (this.$store.state.mypage.result) {
            if (url.path.includes("order_payment")) {
              console.log(url.path);
              if (this.reviewSeq) {
                url.path += `?review_seq=${this.reviewSeq}`;
              }
              this.$router.push(url.path);
              return false;
            }
            this.$router.push("/mypage/shipping_address_list");
          }
        }
        //일반 전화번호 정리
        if (this.phone[1] === "" || this.phone[2] === "") {
          this.phone = ["", null, null];
        }
      } else {
        this.checkPhone = true;
      }
    },
    async getShippingAddress() {
      await this.$store.dispatch("mypage/getShippingAddress", {
        address_seq: this.addressSeq,
      });
      if (this.$store.state.mypage.shippingAddress) {
        this.mode = "update";
        const recipient_phone =
          this.$store.state.mypage.shippingAddress["recipient_phone"].split(
            "-"
          );
        const recipient_cellphone =
          this.$store.state.mypage.shippingAddress["recipient_cellphone"].split(
            "-"
          );

        this.addressDesc =
          this.$store.state.mypage.shippingAddress["address_description"];
        this.addressSeq =
          this.$store.state.mypage.shippingAddress["address_seq"];
        this.userName =
          this.$store.state.mypage.shippingAddress["recipient_user_name"];
        document.querySelector("#zipNo").value =
          this.$store.state.mypage.shippingAddress["recipient_zipcode"];
        document.querySelector("#roadAddr").value =
          this.$store.state.mypage.shippingAddress["recipient_address_street"];
        document.querySelector("#addrDetail").value =
          this.$store.state.mypage.shippingAddress["recipient_address_detail"];

        this.phone = [
          recipient_phone[0],
          recipient_phone[1],
          recipient_phone[1],
        ];
        this.cellphone = [
          recipient_cellphone[0],
          recipient_cellphone[1],
          recipient_cellphone[1],
        ];

        this.defaultCheck =
          this.$store.state.mypage.shippingAddress["default"] === "Y"
            ? true
            : false;
      }
    },
    checkPhoneValue() {
      const phoenReg = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;
      const cellphone = this.cellphone.join("-");
      if (!phoenReg.test(cellphone)) {
        this.checkPhone = true;
        return false;
      }
      this.checkPhone = false;
      return true;
    },
  },
};
</script>

<style></style>
